import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Tracking changes on websites"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>Chances are you probably visit a lot of websites every week for work and pleasure,
            whether it’s checking your competitors, looking for new product ideas, keeping an eye on your suppliers,
              or checking for product availability or pricing changes on products you’re interested in.</p>
            <p>The problem is this type of work or procrastination is time-consuming when you likely have many more valuable activities in your to-do list. </p>
            <p>Hexowatch helps you automate these tedious tasks, working for you 24/7 alerting you when any changes occur.</p>
            <p>But that’s not all… Hexometer can also take a snapshot of a website at any given time, allowing you to compare changes in text, images,
              or code to see if something has been added or removed.</p>

            <ul>
              <li>Pinpoint changes on any page</li>
              <li>Monitor sites product updates</li>
              <li>Receive notifications when a company’s stock meets a certain threshold.</li>
              <li>Get breaking news when a brand is mentioned.</li>
              <li>Know if the price drops on a product you want to buy.</li>
            </ul>
            <p>And this is just the start, the possibilities are endless. Let’s review how you can get started:</p>
            <h4>Step 1: Create an account with a platform that tracks changes</h4>
            <p>Get started with Hexowatch account</p>
            <h4>Step 2: Decide how you would like to monitor a page</h4>
            <p>Depending on your use case, you can monitor any page for visual, content, source code, price, availability, uptime, 
              technology, or keyword changes. 
              {/* Not sure where to start, then use our automatic AI monitor which will alert 
              you whenever any changes are detected. */}
            </p>
            <h4>Step 3: Go back to your day</h4>
            <p>Once you’ve set up your monitors, you can go back to your day safe in the knowledge 
              that you’ll get alerted when changes happen. No more wasted time or opportunities.</p>
            <h4>Go beyond page change monitoring</h4>
            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website,
            keep an archive of every change and turn any website into your own private data source accessing changes
              as a downloadable CSV file, google sheets or via Zapier.</p>
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
